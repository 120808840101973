
import CommunicationViewerModal from "@/components/modals/CommunicationViewerModal.vue";
import moment from "moment";
import {CommunicationViewModel} from "@/models/communications/CommunicationViewModel";
import {defineComponent, ref} from "vue";
import {getCommunicationType} from "@/models/enums/CommunicationType";
import {CommunicationService} from "@/services/CommunicationService";
import {useToast} from "vue-toastification";
import {ProblemDetails} from "@/models/ProblemDetails";

export default defineComponent({
  setup() {
    const communicationViewerModal = ref<InstanceType<typeof CommunicationViewerModal>>();
    const viewCommunication = (_communication: CommunicationViewModel) => {
      communicationViewerModal.value?.toggleModal(_communication);
    }
    const toast = useToast();
    return {communicationViewerModal, viewCommunication, toast}
  },
  components: {CommunicationViewerModal},
  data() {
    return {
      communications: [] as Array<CommunicationViewModel>,
      isLoading: true
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      CommunicationService.fetchAccountCommunications().then(response => {
        this.communications = response.data
      }).catch(err => {
        let errorDetails = err.response.data as ProblemDetails
        this.toast.error(errorDetails.detail)
      }).finally(() => {
        this.isLoading = false
      })
    },
    getFriendlyDateTime(date) {
      return moment(date).format('M/D h:mm a').toString();
    },
    getCommunicationType(type) {
      return getCommunicationType(type)
    },
    getShortFriendlyDateTime(date: Date): string {
      return moment(date).format('MMM Do h:mm a').toString();
    }
  }
})
